


export default function Favourites(props){
  return (
    <div>
      Favourites
    </div>
  )
};

