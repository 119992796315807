


export default function Recent(props){
  return (
    <div>
      Recent
    </div>
  )
};

