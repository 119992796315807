import Default from "../../layouts/default/Default";



export default function Templates(props) {
  return (
    <div>
      <Default>
        Templates
      </Default>
    </div>
  )
};

